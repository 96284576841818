<template>
  <div>
    <currentLocation :sibItem="sibItem"></currentLocation>
    <div class="outer">
      <div class="policy">
        <div class="policyImg">
          <img src="@/assets/img/tpBJ1.png" alt="" class="imgHoverBig" />
          <div>{{ $t("talentPolicy.TP_tipSix") }}</div>
        </div>
        <div class="policyTitle">
          <div>
            {{ $t("talentPolicy.TP_tipOne") }}
          </div>
        </div>
      </div>
      <div class="policy">
        <div class="policyImg">
          <img src="@/assets/img/tpBJ2.png" alt="" class="imgHoverBig" />
          <div>{{ $t("talentPolicy.TP_tipSeven") }}</div>
        </div>
        <div class="policyTitle">
          <div>
            {{ $t("talentPolicy.TP_tipTwo") }}
          </div>
          <div>
            {{ $t("talentPolicy.TP_tipThree") }}
          </div>
        </div>
      </div>
      <div class="policy">
        <div class="policyImg">
          <img src="@/assets/img/tpBJ3.png" alt="" class="imgHoverBig" />
          <div>{{ $t("talentPolicy.TP_tipEight") }}</div>
        </div>
        <div class="policyTitle">
          <div>
            {{ $t("talentPolicy.TP_tipFour") }}
          </div>
          <div>
            {{ $t("talentPolicy.TP_tipFive") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：人才招聘-人才政策
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-08-15 11:16
 */
import currentLocation from "@/components/currentLocation.vue";
export default {
  components: {
    currentLocation,
  },
  data() {
    return {
      sibItem: [
        {
          title_zh: "人才招聘",
          title_en: "Talent recruitment",
          path: "recruitment",
        },
        {
          title_zh: "人才政策",
          title_en: "Talent policy",
          path: "",
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style lang='scss' scoped>
.outer {
  background: #f6f6f6;
  padding: 78px 310px 40px;
}

.policy {
  background: #ffffff;
  padding: 70px 108px 20px 0;
  display: flex;
  justify-content: space-between;
  margin-bottom: 80px;
}
.policyImg {
  width: 330px !important;
  height: 180px;
  overflow: hidden;
  margin: -100px 0 0 -20px;
  position: relative;
}
.policyImg img {
  width: 100%;
  height: 100%;

  transition: all 0.9s !important;
}

.policyImg div {
  font-size: 30px;
  font-weight: bold;
  color: #ffffff;
  margin: auto auto;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.policyImg:hover {
  > img {
    transform: scale(1.2) !important;
  }
}

.policyTitle {
  width: 65%;
}

.policyTitle > div {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #444444;
  line-height: 36px;
  margin-bottom: 60px;
}
</style>